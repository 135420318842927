@mixin pageHeaderPadding($extraPadding: 0px) {
  padding-top: calc(72px + #{$extraPadding});

  @include media(desktopAll) {
    padding-top: calc(96px + #{$extraPadding});
  }

  @include media(desktopHuge) {
    padding-top: calc(112px + #{$extraPadding});
  }
}